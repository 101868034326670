import React, { useContext, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import CitasCRMContext from "../../context/citasCRM/citasCRMContext";

const ClienteSearch = () => {
  const citasCRMContext = useContext(CitasCRMContext);

  const [clienteTelefono, setClienteTelefono] = useState();
  const [clienteNombre, setClienteNombre] = useState();

  const onSubmit = (e) => {
    e.preventDefault();
    if (!clienteTelefono && !clienteNombre) {
      citasCRMContext.setAlert({
        severity: "warn",
        summary: "Campos invalidos",
        detail: "Debe poner nombre o telefono para buscar clientes",
        life: 3000,
      });
      return;
    }
    citasCRMContext.listClientes(clienteTelefono, clienteNombre);
    clearForm();
  };

  const clearForm = () => {
    setClienteTelefono("");
    setClienteNombre("");
  };

  return (
    <form onSubmit={onSubmit} className="form">
      <span className="p-input-icon-left m-1">
        <i className="pi pi-phone" />
        <InputMask
          id="telefono"
          mask="(999)-999-9999"
          placeholder="(999)-999-9999"
          value={clienteTelefono}
          unmask={true}
          onChange={(e) => setClienteTelefono(e.target.value)}
        />
      </span>{" "}
      ó
      <span className="p-input-icon-left m-1">
        <i className="pi pi-search" />
        <InputText
          id="nombre"
          value={clienteNombre}
          onChange={(e) => setClienteNombre(e.target.value)}
          placeholder="Buscar por Nombre..."
        />
      </span>
      <input type="submit" value="Buscar" className="btn btn-dark btn-block" />
    </form>
  );
};

export default ClienteSearch;
