import React, { useContext } from "react";
import ClienteSearch from "../../components/cliente/ClienteSearch";
import Loader from "../../components/animations/Loader";
import ClienteItem from "../../components/cliente/ClienteItem";
import CitasCRMContext from "../../context/citasCRM/citasCRMContext";

export default function Clientes() {
  const citasCRMContext = useContext(CitasCRMContext);

  return (
    <div>
      <h5 className="my-2">
        <i className="fa-solid fa-user-group"></i> Clientes
      </h5>
      <div className="container">
        <ClienteSearch />
        {citasCRMContext.loading ? (
          <div className="mx-auto my-4" style={{ width: "10rem" }}>
            <Loader />
          </div>
        ) : (
          <div className="my-4 cliente-list">
            {citasCRMContext.clientes.map((cliente) => (
              <ClienteItem key={cliente.cliente_id} cliente={cliente} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
