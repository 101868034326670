import React from "react";
import logo from "../../assets/images/logo-leaf-nobackground-1000.png";
import { NavLink } from "react-router-dom";

function Navbar() {
  return (
    <nav className="navbar navbar-expand-lg bg-primary-local">
      <div className="container-fluid">
        <div className="navbar-brand align-middle d-flex">
          <img src={logo} alt="Logo" style={{ width: "3.5rem" }} />
          <div className=" d-flex flex-column">
            <span className="brand-name secondary-color-local">CitasCRM</span>
            <span className="small-subtitle-text">by mimixtech</span>
          </div>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarContent"
          aria-controls="navbarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink to="/" className="nav-link" aria-current="page">
                <i className="fa-regular fa-calendar-check"></i> Citas
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/clientes" className="nav-link">
                <i className="fa-solid fa-user-group"></i> Clientes
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/reportes" className="nav-link">
                <i className="fa-solid fa-chart-pie"></i> Reportes
              </NavLink>
            </li>
          </ul>
          <NavLink to="/settings" className="nav-link m-1">
            <i className="fa-solid fa-gear"></i> Settings
          </NavLink>
          <button className="nav-link m-1 btn">
            <i className="fa-regular fa-user"></i> Cerrar Session
          </button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
