import React from "react";
import logosvg from "../../assets/images/logo-nobackground.svg";

function Loader() {
  return (
    <div className="lds-default">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <img src={logosvg} alt="Logo SVG" className="animated-svg" />
    </div>
  );
}

export default Loader;
