import React, { useState } from "react";
import { Chart } from "primereact/chart";

function CitasChart() {
  const [basicData] = useState({
    labels: [
      "Dic 4 - Dic 10",
      "Dic 11 - Dic 17",
      "Dic 18 - Dic 24",
      "Dic 25 - Dic 31",
    ],
    datasets: [
      {
        label: "Citas por semana",
        backgroundColor: "#42A5F5",
        data: [75, 65, 72, 43],
      },
    ],
  });

  const basicOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
      y: {
        ticks: {
          color: "#495057",
        },
        grid: {
          color: "#ebedef",
        },
      },
    },
  };
  return (
    <div>
      <h5>Citas por Semana</h5>
      <Chart type="bar" data={basicData} options={basicOptions} />
    </div>
  );
}

export default CitasChart;
