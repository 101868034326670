import {
  LIST_CLIENTES,
  GET_CLIENTE,
  LIST_CITAS,
  SET_CITAS_GROUPED_BY_DAY,
  SET_CITAS_DATE,
  SET_CITAS_DATE_STR,
  SET_INTERVAL_TYPE,
  GET_CITA,
  SET_LOADING,
  LIST_SERVICIOS,
  GET_SERVICIO,
} from "../types";

const CitasCRMReducer = (state, action) => {
  switch (action.type) {
    case LIST_CITAS:
      return {
        ...state,
        citas: action.payload,
        loading: false,
      };
    case SET_CITAS_GROUPED_BY_DAY:
      return {
        ...state,
        citasGroupedByDay: action.payload,
      };
    case SET_CITAS_DATE:
      return {
        ...state,
        citasDate: action.payload,
      };
    case SET_CITAS_DATE_STR:
      return {
        ...state,
        citasDateStr: action.payload,
      };
    case SET_INTERVAL_TYPE:
      return {
        ...state,
        intervalType: action.payload,
      };
    case GET_CITA:
      return {
        ...state,
        cita: action.payload,
        loading: false,
      };
    case LIST_CLIENTES:
      return {
        ...state,
        clientes: action.payload,
        loading: false,
      };
    case GET_CLIENTE:
      return {
        ...state,
        cliente: action.payload,
        loading: false,
      };
    case LIST_SERVICIOS:
      return {
        ...state,
        servicios: action.payload,
        loading: false,
      };
    case GET_SERVICIO:
      return {
        ...state,
        servicio: action.payload,
        loading: false,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default CitasCRMReducer;
