import React, { Fragment, useContext, useEffect } from "react";
import CitasCRMContext from "../../../context/citasCRM/citasCRMContext";
import { NavLink } from "react-router-dom";

function CitasCalendarHeader() {
  const citasCRMContext = useContext(CitasCRMContext);

  const onChangeValue = (e) => {
    citasCRMContext.listCitasByInterval(e.target.value);
  };

  const fetchInitialData = () => {
    citasCRMContext.listCitasByInterval("TODAY");
  };

  const addToDate = () => {
    citasCRMContext.addToDate();
  };

  const subtractFromDate = () => {
    citasCRMContext.subtractFromDate();
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  return (
    <Fragment>
      <div className="col-sm">
        <div className="d-flex justify-content-center">
          <div className="btn-group">
            <button className="btn btn-sm" onClick={subtractFromDate}>
              <i className="fa-solid fa-chevron-left"></i>
            </button>
            <button className="btn btn-sm" onClick={addToDate}>
              <i className="fa-solid fa-chevron-right"></i>
            </button>
          </div>

          <NavLink to="citacreate" className="btn btn-sm btn-outline-local">
            <i className="fa-regular fa-calendar-plus"></i> Agendar Cita
          </NavLink>
        </div>
      </div>
      <div className="col-sm">
        <h5>{citasCRMContext.citasDateStr}</h5>
      </div>
      <div className="col-sm">
        <div
          className="btn-group"
          role="group"
          aria-label="Basic radio toggle button group"
          onChange={onChangeValue}
        >
          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio1"
            autoComplete="off"
            value="TODAY"
            defaultChecked
          />
          <label className="btn btn-sm btn-outline-local" htmlFor="btnradio1">
            Dia
          </label>

          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio2"
            value="WEEK"
            autoComplete="off"
          />
          <label className="btn btn-sm btn-outline-local" htmlFor="btnradio2">
            Semana
          </label>

          <input
            type="radio"
            className="btn-check"
            name="btnradio"
            id="btnradio3"
            value="MONTH"
            autoComplete="off"
          />
          <label className="btn btn-sm btn-outline-local" htmlFor="btnradio3">
            Mes
          </label>
        </div>
      </div>
    </Fragment>
  );
}

export default CitasCalendarHeader;
