import axios from "axios";

class ClienteService {
  async listClientes(negocio_id, phone, name) {
    try {
      let params = new URLSearchParams();
      if (phone) params.append("cliente_telefono", phone);
      if (name) params.append("cliente_nombres", name);
      const res = await axios.get("/data/clientes.json", (params = { params }));
      return res.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async getClienteById(id) {
    try {
      const res = await axios.get("/data/clientes.json");
      const cliente = res.data.filter(
        (cliente) => cliente.cliente_id === id
      )[0];
      return cliente;
    } catch (error) {
      console.log(error);
      return {};
    }
  }
}

export default ClienteService;
