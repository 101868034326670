import React from "react";
import CitasChart from "../../components/reportes/CitasChart";
import ServiciosChart from "../../components/reportes/ServiciosChart";

function Reportes() {
  return (
    <div>
      <h5 className="my-2">
        <i className="fa-solid fa-chart-pie"></i> Reportes
      </h5>
      <div className="container">
        <div className="my-3">
          <CitasChart />
        </div>
        <div className="my-3">
          <ServiciosChart />
        </div>
      </div>
    </div>
  );
}

export default Reportes;
