import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import ClienteCreateForm from "../../components/cliente/ClienteCreateForm";

function ClienteCreate() {
  const navigate = useNavigate();
  return (
    <Fragment>
      <button className="btn ps-0" onClick={() => navigate(-1)}>
        <i className="fa-solid fa-chevron-left"></i> Atras
      </button>
      <div className="my-2">
        <i className="fa-solid fa-user-plus"></i>{" "}
        <span className="fs-5">Crear Cliente</span>
      </div>
      <div className="card my-3">
        <div className="card-body w-100">
          <ClienteCreateForm />
        </div>
      </div>
    </Fragment>
  );
}

export default ClienteCreate;
