import React, { Fragment } from "react";
import CitasCalendarBody from "./CitasCalendarBody";
import CitasCalendarHeader from "./CitasCalendarHeader";

const CitasCalendar = () => {
  return (
    <Fragment>
      <div className="row">
        <CitasCalendarHeader />
      </div>
      <div className="row">
        <CitasCalendarBody />
      </div>
    </Fragment>
  );
};

export default CitasCalendar;
