import React from "react";
import { Button } from "primereact/button";
import DateUtils from "../../../utils/DateUtils";

function CitaItem({ cita }) {
  const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
  const dateUtils = new DateUtils();

  return (
    <div
      className="card m-2"
      style={{
        borderLeft: `5px solid ${randomColor}`,
        borderRight: `5px solid ${randomColor}`,
      }}
    >
      <div className="card-body w-100">
        <div className="d-md-flex w-100">
          <div className="align-self-md-start">
            <Button
              label={dateUtils.convertTimestampToLocal(
                cita.fecha_epoch,
                "h:mm aa"
              )}
              className="p-button-sm p-button-rounded me-1"
            />
          </div>
          <div className="align-self-md-end">
            <div className="row w-100">
              <small className="col">
                {" "}
                <span className="fw-bolder">
                  {cita.cliente.cliente_nombres}{" "}
                  {cita.cliente.cliente_apellidos}
                </span>
              </small>
              <small className="col ms-4">
                {" "}
                <span className="fw-light">Servicios:</span>
                <span className="fw-bolder">
                  {Object.keys(cita.servicios).map((key) => (
                    <div
                      className="badge rounded-pill text-bg-info mx-1"
                      key={key}
                    >
                      {cita.servicios[key].servicio_nombre}
                    </div>
                  ))}
                </span>
              </small>
            </div>
            <div className="row">
              <div className="col">
                {cita.comentario ? (
                  <small>
                    <span className="fw-light">Comentario:</span>{" "}
                    <span className="fw-bolder">{cita.comentario}</span>
                  </small>
                ) : (
                  ""
                )}
              </div>
              <div className="col"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CitaItem;
