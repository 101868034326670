import React, { useState } from "react";
import { Chart } from "primereact/chart";

function ServiciosChart() {
  const [chartData] = useState({
    labels: ["Facial Express", "Facial Profundo", "Peeling Anti-acné"],
    datasets: [
      {
        data: [175, 100, 60],
        backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
      },
    ],
  });

  const [lightOptions] = useState({
    plugins: {
      legend: {
        labels: {
          color: "#495057",
        },
      },
    },
  });

  return (
    <div>
      <h5>Servicios mas solicitados</h5>
      <div className="card d-flex justify-content-center">
        <Chart
          type="doughnut"
          data={chartData}
          options={lightOptions}
          style={{ position: "relative", width: "60%", margin: "auto" }}
        />
      </div>
    </div>
  );
}

export default ServiciosChart;
