// Primereact
import "primereact/resources/themes/saga-green/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
// Custom CSS
import "./App.css";

import Navbar from "./components/layout/Navbar";
import Alert from "./components/layout/Alert";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CitasCRMState from "./context/citasCRM/CitasCRMState";
import Cita from "./views/citas/Citas";
import Clientes from "./views/cliente/Clientes";
import Reportes from "./views/reportes/Reportes";
import Settings from "./views/settings/Settings";
import Cliente from "./views/cliente/Cliente";
import CitaCreate from "./views/citas/CitaCreate";
import ClienteCreate from "./views/cliente/ClienteCreate";
import ServicioCreate from "./views/servicio/ServicioCreate";
import PlantillaCreate from "./views/plantilla/PlantillaCreate";
import SignInPage from "./views/authentication/SignInPage";
import PerfilView from "./views/settings/perfil";
import NegocioView from "./views/settings/negocio";
import ServicioView from "./views/settings/servicios";
import MensajeriaView from "./views/settings/mensajeria";
import UsuariosView from "./views/settings/usuarios";
import NotFound from "./views/NotFound/NotFound";

function App() {
  return (
    <CitasCRMState>
      <Router>
        <div className="App">
          <Navbar />
          <div className="container">
            <Alert />
            <Routes>
              <Route exact path="/" element={<Cita />} />
              <Route exact path="/signin" element={<SignInPage />} />
              <Route exact path="/citacreate" element={<CitaCreate />} />
              <Route exact path="/clientecreate" element={<ClienteCreate />} />
              <Route
                exact
                path="/serviciocreate"
                element={<ServicioCreate />}
              />
              <Route
                exact
                path="/plantillacreate"
                element={<PlantillaCreate />}
              />
              <Route exact path="/clientes" element={<Clientes />} />
              <Route exact path="/reportes" element={<Reportes />} />
              <Route exact path="/settings" element={<Settings />}>
                <Route index element={<PerfilView />} />
                <Route exact path="perfil" element={<PerfilView />} />
                <Route exact path="negocio" element={<NegocioView />} />
                <Route exact path="servicios" element={<ServicioView />} />
                <Route exact path="mensajeria" element={<MensajeriaView />} />
                <Route exact path="usuarios" element={<UsuariosView />} />
              </Route>
              <Route exact path="/cliente/:cliente_id" element={<Cliente />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
        </div>
      </Router>
    </CitasCRMState>
  );
}

export default App;
