import React from "react";
import PropTypes from "prop-types";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import Loader from "../animations/Loader";
import ClienteHistoriaClinica from "./ClienteHistoriaClinica";

const ClienteDetails = ({
  cliente: {
    cliente_id,
    cliente_nombres,
    cliente_apellidos,
    cliente_telefono,
    cliente_ficha,
  },
}) => {
  const getRandomColor = () =>
    "#" + Math.floor(Math.random() * 16777215).toString(16);

  if (!cliente_id) {
    return <Loader />;
  }

  return (
    <div className="card my-2 mx-auto main-card">
      <div className="card-body">
        <div className="d-flex">
          <Avatar
            icon="pi pi-user"
            className="mr-2"
            size="xlarge"
            shape="circle"
            style={{
              backgroundColor: getRandomColor(),
              color: "#fff",
            }}
          />{" "}
          <div className="p-1 m-1">
            <h4 className="fw-bold">
              {cliente_nombres} {cliente_apellidos}
            </h4>
            <i className="pi pi-whatsapp" style={{ color: "#07a384" }} />{" "}
            {`${cliente_telefono.slice(2, 5)}-${cliente_telefono.slice(
              5,
              8
            )}-${cliente_telefono.slice(8)}`}
          </div>
          <Button
            icon="pi pi-user-edit"
            className="p-button-rounded p-button-info p-button-text ms-auto"
            aria-label="User"
            label="Editar"
          />
        </div>
        <h5 className="mt-3">
          <i className="fa-solid fa-notes-medical"></i> Historia Clinica
        </h5>
        <ClienteHistoriaClinica cliente_ficha={cliente_ficha} />
      </div>
    </div>
  );
};

ClienteDetails.propTypes = {
  cliente: PropTypes.object.isRequired,
};

export default ClienteDetails;
