import React, { useContext, useEffect, useState } from "react";
import Loader from "../../animations/Loader";
import CitaItem from "./CitaItem";
import CitasCRMContext from "../../../context/citasCRM/citasCRMContext";

function CitasCalendarBody() {
  const [citasGroup, setCitasGroup] = useState([]);
  const citasCRMContext = useContext(CitasCRMContext);

  useEffect(() => {
    setCitasGroup(citasCRMContext.citasGroupedByDay);
  }, [citasCRMContext.citasGroupedByDay]);

  if (citasCRMContext.loading) {
    <div className="col container">
      <div className="card mt-3">
        <div className="card-body">
          <div className="mx-auto my-2">
            <Loader />
          </div>
        </div>
      </div>
    </div>;
  }

  return (
    <div className="col container">
      <div className="card mt-3">
        <div className="card-body scroll">
          <div className="my-1">
            {Object.keys(citasGroup).map((citaDay) => (
              <div key={citaDay}>
                {Object.keys(citasGroup).length > 1 ? (
                  <div className="container">
                    <p className="citaday-text my-3">{citaDay}</p>
                  </div>
                ) : (
                  ""
                )}

                {citasGroup[citaDay].map((cita) => (
                  <CitaItem cita={cita} key={cita.cita_id} />
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CitasCalendarBody;
