export const LIST_CLIENTES = "LIST_CLIENTES";
export const GET_CLIENTE = "GET_CLIENTE";
export const LIST_CITAS = "LIST_CITAS";
export const SET_CITAS_GROUPED_BY_DAY = "SET_CITAS_GROUPED_BY_DAY";
export const SET_CITAS_DATE = "SET_CITAS_DATE";
export const SET_CITAS_DATE_STR = "SET_CITAS_DATE_STR";
export const SET_INTERVAL_TYPE = "SET_INTERVAL_TYPE";
export const GET_CITA = "GET_CITA";
export const SET_LOADING = "SET_LOADING";
export const LIST_SERVICIOS = "LIST_SERVICIOS";
export const GET_SERVICIO = "GET_SERVICIO";
