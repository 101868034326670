import React, { useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Chips } from "primereact/chips";

function ServicioCreateForm() {
  const [servicioNombre, setServicioNombre] = useState();
  const [servicioCosto, setServicioCosto] = useState(0);
  const [servicioCategoria, setServicioCategoria] = useState();
  const [servicioDescripcion, setServicioDescripcion] = useState();
  const [duracionMinutos, setDuracionMinutos] = useState(15);
  const [servicioFeatures, setServicioFeatures] = useState([]);
  const [plantillas, setPlantillas] = useState([]);
  const [selectedPlantilla, setSelectedPlantilla] = useState();
  const [recomendacionesAntesDe, setRecomendacionesAntesDe] = useState([]);
  const [recomendacionesDespuesDe, setRecomendacionesDespuesDe] = useState([]);

  const onPlantillaChange = (e) => {
    setSelectedPlantilla(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="my-2 row">
        <div className="col-sm">
          <h5>Nombre del Servicio</h5>
          <InputText
            id="servicio-nombre"
            value={servicioNombre}
            onChange={(e) => setServicioNombre(e.target.value)}
          />
        </div>
        <div className="col-sm">
          <h5>Precio</h5>
          <InputNumber
            inputId="servicio-costo"
            value={servicioCosto}
            onValueChange={(e) => setServicioCosto(e.target.value)}
            mode="currency"
            currency="USD"
            locale="en-Us"
            showButtons
            min={0}
          />
        </div>
      </div>
      <div className="my-2 row">
        <div className="col-sm">
          <h5>Categoria</h5>
          <InputText
            id="servicio-categoria"
            value={servicioCategoria}
            onChange={(e) => setServicioCategoria(e.target.value)}
          />
        </div>
        <div className="col-sm">
          <h5>Duracion en minutos</h5>
          <InputNumber
            inputId="duracion-minutos"
            value={duracionMinutos}
            onValueChange={(e) => setDuracionMinutos(e.target.value)}
            mode="decimal"
            step={5}
            showButtons
            min={15}
          />
        </div>
      </div>
      <div className="my-2">
        <div className="row">
          <div className="col-sm">
            <h5>Plantillas de mensajes</h5>
            <Dropdown
              id="cliente"
              value={selectedPlantilla}
              options={plantillas}
              onChange={onPlantillaChange}
              optionLabel="plantilla_nombre"
              optionValue="plantilla_id"
              filter
              showClear
              filterBy="plantilla_nombre"
              placeholder="Selecciona una Plantilla"
            />
          </div>
          <div className="col-sm">
            <h5>
              Features del servicio <small>(que incluye)</small>
            </h5>
            <Chips
              value={servicioFeatures}
              onChange={(e) => setServicioFeatures(e.value)}
              separator=","
            />
          </div>
        </div>
      </div>
      <div className="my-2 row">
        <div className="col-sm">
          <h5>Recomendaciones antes de Servicio</h5>
          <Chips
            value={recomendacionesAntesDe}
            onChange={(e) => setRecomendacionesAntesDe(e.target.value)}
            separator=","
            tooltip="Separar cada recomendacion con commas"
          />
        </div>
        <div className="col-sm">
          <h5>Recomendaciones despues de Servicio</h5>
          <Chips
            value={recomendacionesDespuesDe}
            onChange={(e) => setRecomendacionesDespuesDe(e.target.value)}
            separator=","
            tooltip="Separar cada recomendacion con commas"
          />
        </div>
      </div>
      <div className="my-2">
        <h5>Descripcion</h5>
        <InputTextarea
          value={servicioDescripcion}
          onChange={(e) => setServicioDescripcion(e.target.value)}
          rows={5}
          cols={30}
          autoResize
        />
      </div>
      <div className="my-2">
        <button type="submit" className="btn btn-submit btn-primary">
          Crear Servicio
        </button>
      </div>
    </form>
  );
}

export default ServicioCreateForm;
