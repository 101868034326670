import React, { Fragment } from "react";
import notfound from "../../assets/images/404notfound.png";

function NotFound() {
  return (
    <Fragment>
      <div>
        <img src={notfound} alt="Not Found" />
      </div>
    </Fragment>
  );
}

export default NotFound;
