import React, { useContext, useState } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import CitasCRMContext from "../../context/citasCRM/citasCRMContext";
import { NavLink } from "react-router-dom";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";

// TODO La plantilla debe pertenecer a la cita, no al Servicio.
function CitaCreateForm() {
  const [selectedCliente, setSelectedCliente] = useState();
  const [clientes, setClientes] = useState();
  const [citaFecha, setCitaFecha] = useState();
  const [duracionMinutos, setDuracionMinutos] = useState(15);
  const [selectedServicio, setSelectedServicio] = useState();
  const [servicios, setServicios] = useState();
  const [comentario, setComentario] = useState();

  const citasCRMContext = useContext(CitasCRMContext);

  const onClienteFocus = (e) => {
    citasCRMContext.listClientes();
    setClientes(citasCRMContext.clientes);
  }; // TODO Fix the delay on loading the dropdown.

  const onClienteChange = (e) => {
    setSelectedCliente(e.target.value);
  };

  const clienteOptionTemplate = (option) => {
    return `${option.cliente_nombres} ${option.cliente_apellidos}`;
  };

  const onServicioFocus = (e) => {
    citasCRMContext.listServicios();
    setServicios(citasCRMContext.servicios);
  };

  const onServicioChange = (e) => {
    setSelectedServicio(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!selectedCliente || !selectedServicio || !citaFecha) {
      citasCRMContext.setAlert({
        severity: "warn",
        summary: "Campos invalidos",
        detail: "Debe seleccionar cliente, servicio y fecha de la cita.",
        life: 3000,
      });
      return;
    }
    clearForm();
  };

  const clearForm = () => {
    setSelectedCliente();
    setSelectedServicio();
    setCitaFecha();
    setDuracionMinutos(15);
    setComentario("");
  };

  // useEffect(() => {
  //   citasCRMContext.listClientes();
  //   setClientes(citasCRMContext.clientes);
  //   console.log("CLIENTES");
  //   console.log(clientes);
  // }, [clientes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={onSubmit}>
      <div className="my-2">
        <h5>Cliente</h5>
        <div className="row">
          <div className="col-sm">
            <Dropdown
              id="cliente"
              value={selectedCliente}
              options={clientes}
              onChange={onClienteChange}
              onFocus={onClienteFocus}
              optionLabel={clienteOptionTemplate}
              optionValue="cliente_id"
              filter
              showClear
              filterBy="cliente_nombres,cliente_apellidos"
              placeholder="Selecciona un Cliente"
            />
          </div>

          <div className="col-sm m-2">
            <NavLink to="/clientecreate" className="btn btn-outline-local">
              <i className="fa-solid fa-user-plus"></i> Crear Cliente
            </NavLink>
          </div>
        </div>
      </div>
      <div className="my-2">
        <h5>Servicios</h5>
        <div className="row">
          <div className="col-sm">
            <MultiSelect
              id="servicio"
              value={selectedServicio}
              options={servicios}
              onChange={onServicioChange}
              onFocus={onServicioFocus}
              optionLabel="servicio_nombre"
              optionValue="servicio_id"
              filter
              showClear
              filterBy="servicio_nombre,servicio_categoria"
              placeholder="Selecciona un Servicio"
            />
          </div>

          <div className="col-sm m-2">
            <NavLink to="/serviciocreate" className="btn btn-outline-local">
              <i className="fa-solid fa-hand-holding-heart"></i> Crear Servicio
            </NavLink>
          </div>
        </div>
      </div>

      <div className="my-2">
        <h5>Fecha</h5>
        <Calendar
          id="cita-fecha"
          value={citaFecha}
          onChange={(e) => setCitaFecha(e.target.value)}
          showTime
          hourFormat="12"
        />
      </div>
      <div className="my-2">
        <h5>Duracion en minutos</h5>
        <InputNumber
          inputId="duracion-minutos"
          value={duracionMinutos}
          onValueChange={(e) => setDuracionMinutos(e.target.value)}
          mode="decimal"
          step={5}
          showButtons
          min={15}
        />
      </div>

      <div className="my-2">
        <h5>Comentario</h5>
        <InputTextarea
          value={comentario}
          onChange={(e) => setComentario(e.target.value)}
          rows={5}
          cols={30}
          autoResize
        />
      </div>
      <div className="my-2">
        <button type="submit" className="btn btn-submit btn-primary">
          Crear Cita
        </button>
      </div>
    </form>
  );
}

export default CitaCreateForm;
