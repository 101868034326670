import React, { useState } from "react";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { MultiSelect } from "primereact/multiselect";
import { Chips } from "primereact/chips";

function ClienteCreateForm() {
  const [clienteNombres, setClienteNombres] = useState();
  const [clienteApellidos, setclienteApellidos] = useState();
  const [clienteTelefono, setClienteTelefono] = useState();
  const [clienteFicha, setClienteFicha] = useState({});
  const [addFicha, setAddFicha] = useState(false);

  const generoOptions = [
    { label: "Masculino", value: "M" },
    { label: "Femenino", value: "F" },
  ];

  const enfermedadesOptions = [
    {
      label: "Enfermedades Cardiacas (hipertension-hipotension)",
      value: "Enfermedades Cardiacas",
    },
    {
      label: "Enfermedades Circulatorias",
      value: "Enfermedades Circulatorias",
    },
    { label: "Enfermedades Digestivas", value: "Enfermedades Digestivas" },
    { label: "Enfermedades Renales", value: "Enfermedades Renales" },
    { label: "Hepatitis", value: "Hepatitis" },
    { label: "Problemas de tension o estres", value: "Tension o estres" },
    { label: "Convulsiones o Epilepsia", value: "Convulsiones o epilepsia" },
    { label: "Implantes faciales", value: "Implantes faciales" },
    { label: "Implantes corporales", value: "Implantes corporales" },
    { label: "Implantes dentales", value: "Implantes dentales" },
    { label: "Placas metalicas", value: "Placas metalicas" },
    {
      label: "Alergias comidas, bebidas o medicamentos",
      value: "Alergias comidas, bebidas o medicamentos",
    },
    { label: "Ha sufrido fractura facial", value: "Fractura Facial" },
    { label: "Problemas de Tiroides", value: "Problemas de Tiroides" },
    { label: "Lupus", value: "Lupus" },
    { label: "Cancer", value: "Cancer" },
    { label: "Hemofilia o anemias", value: "Hemofilia o anemias" },
    { label: "Consume Antidepresivos", value: "Consume Antidepresivos" },
    { label: "Drogas", value: "Drogas" },
    { label: "Hidroquinona", value: "Hidroquinona" },
    { label: "Alergico a la aspirina", value: "Alergico a la aspirina" },
    { label: "Cancer de piel", value: "Cancer de piel" },
    { label: "Isotretinoina", value: "Isotretinoina" },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="row my-2">
        <div className="col-sm">
          <h5>Cliente Nombres</h5>
          <InputText
            id="cliente-nombres"
            value={clienteNombres}
            onChange={(e) => setClienteNombres(e.target.value)}
          />
        </div>
        <div className="col-sm">
          <h5>Cliente Apellidos</h5>
          <InputText
            id="cliente-apellidos"
            value={clienteApellidos}
            onChange={(e) => setclienteApellidos(e.target.value)}
          />
        </div>
      </div>
      <div className="row my-2">
        <div className="col-sm">
          <h5>Cliente Telefono</h5>
          <span className="p-input-icon-left m-1">
            <i className="pi pi-phone" />
            <InputMask
              id="telefono"
              mask="(999)-999-9999"
              placeholder="(999)-999-9999"
              value={clienteTelefono}
              unmask={true}
              onChange={(e) => setClienteTelefono(e.target.value)}
            />
          </span>
        </div>
      </div>
      {!addFicha ? (
        <div className="my-2">
          <button
            className="btn btn-outline-local m-2"
            onClick={() => setAddFicha(true)}
          >
            <i className="fa-solid fa-notes-medical"></i> Agregar Historia
            Clinica
          </button>
        </div>
      ) : (
        <div className="my-2">
          <div className="my-2">
            <h4>
              <i className="pi pi-calendar" /> Datos Personales
            </h4>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Fecha de Nacimiento</h5>
                <Calendar
                  value={clienteFicha.fechaNacimiento}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      fechaNacimiento: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Ocupacion</h5>
                <InputText
                  id="cliente-ocupacion"
                  value={clienteFicha.ocupacion}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      ocupacion: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Genero</h5>
                <Dropdown
                  id="cliente-genero"
                  value={clienteFicha.genero}
                  options={generoOptions}
                  onChange={(e) =>
                    setClienteFicha({ ...clienteFicha, genero: e.target.value })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Motivo de visita</h5>
                <InputText
                  id="cliente-motivo"
                  value={clienteFicha.motivo}
                  onChange={(e) =>
                    setClienteFicha({ ...clienteFicha, motivo: e.target.value })
                  }
                />
              </div>
            </div>
          </div>
          <div className="my-2">
            <h4>
              <i className="fa-solid fa-briefcase-medical" /> Datos Medicos
              Generales
            </h4>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Ha tenido o tiene alguna condicion medica</h5>
                <InputSwitch
                  checked={clienteFicha.condicionMedica}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      condicionMedica: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Detalle de condicion medica</h5>
                <InputText
                  value={clienteFicha.condicionMedicaDetalle}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      condicionMedicaDetalle: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Fuma</h5>
                <InputSwitch
                  checked={clienteFicha.fuma}
                  onChange={(e) =>
                    setClienteFicha({ ...clienteFicha, fuma: e.target.value })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Toma Alcohol</h5>
                <InputSwitch
                  checked={clienteFicha.alcohol}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      alcohol: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Hooka</h5>
                <InputSwitch
                  checked={clienteFicha.hooka}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      hooka: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Enfermedades (ha tenido o tiene)</h5>
                <MultiSelect
                  id="cliente-enfermedades"
                  value={clienteFicha.enfermedades}
                  options={enfermedadesOptions}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      enfermedades: e.target.value,
                    })
                  }
                  filter
                  showClear
                />
              </div>
            </div>
          </div>
          <div className="my-1">
            <h5>
              <i className="fa-solid fa-hospital-user" /> Antecedentes Medicos
            </h5>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Esta embarazada</h5>
                <InputSwitch
                  id="cliente-embarazada"
                  checked={clienteFicha.embarazada}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      embarazada: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Semanas de embarazo</h5>
                <InputNumber
                  inputId="semanas-embarazo"
                  value={clienteFicha.semanasEmbarazo}
                  onValueChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      semanasEmbarazo: e.target.value,
                    })
                  }
                  mode="decimal"
                  step={1}
                  showButtons
                  min={0}
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Fecha de ultimo embarazo</h5>
                <Calendar
                  value={clienteFicha.fechaUltimoEmbarazo}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      fechaUltimoEmbarazo: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Lactando</h5>
                <InputSwitch
                  id="cliente-lactando"
                  checked={clienteFicha.lactando}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      lactando: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Se ha depilado</h5>
                <InputSwitch
                  id="cliente-depilado"
                  checked={clienteFicha.depilado}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      depilado: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Areas y metodo de depilacion</h5>
                <InputText
                  value={clienteFicha.areasMetodoDepilacion}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      areasMetodoDepilacion: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Tratamientos Dermatologicos</h5>
                <InputText
                  value={clienteFicha.tratamientoDermatologicos}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      tratamientoDermatologicos: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Medicamentos de consumo actual</h5>
                <InputText
                  value={clienteFicha.medicamentos}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      medicamentos: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Horas de sueño</h5>
                <InputNumber
                  inputId="horas-sueno"
                  value={clienteFicha.horasSueno}
                  onValueChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      horasSueno: e.target.value,
                    })
                  }
                  mode="decimal"
                  step={1}
                  showButtons
                  min={0}
                />
              </div>
              <div className="col-sm">
                <h5>Vasos de agua que consume al dia</h5>
                <InputNumber
                  inputId="vasos-agua-dia"
                  value={clienteFicha.vasosAguaDia}
                  onValueChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      vasosAguaDia: e.target.value,
                    })
                  }
                  mode="decimal"
                  step={1}
                  showButtons
                  min={0}
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Consume frutas y vegetales</h5>
                <InputSwitch
                  id="frutas-vegetales"
                  checked={clienteFicha.consumeFrutasVegetales}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      consumeFrutasVegetales: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Tiene periodo menstrual regular</h5>
                <InputSwitch
                  id="frutas-vegetales"
                  checked={clienteFicha.periodoMenstrualRegular}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      periodoMenstrualRegular: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Productos faciales cosmeticos de uso diario</h5>
                <Chips
                  value={clienteFicha.productosFacialesCosmeticos}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      productosFacialesCosmeticos: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Fecha de ultimo periodo</h5>
                <Calendar
                  value={clienteFicha.fechaUltimoPeriodo}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      fechaUltimoPeriodo: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Tratamientos faciales que realiza en casa</h5>
                <Chips
                  value={clienteFicha.tratamientosFacialesCasa}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      tratamientosFacialesCasa: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Herpes Simplex</h5>
                <InputSwitch
                  id="herpes-simplex"
                  checked={clienteFicha.herpesSimplex}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      herpesSimplex: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Usa lentes de contacto</h5>
                <InputSwitch
                  id="lentes-contacto"
                  checked={clienteFicha.lentesContacto}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      lentesContacto: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Ha sufrido de acné</h5>
                <InputSwitch
                  id="acne"
                  checked={clienteFicha.acne}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      acne: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Tiene problemas de cicatrizacion</h5>
                <InputSwitch
                  id="problemasCicatrizacion"
                  checked={clienteFicha.problemasCicatrizacion}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      problemasCicatrizacion: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Aquejas</h5>
                <InputText
                  value={clienteFicha.aquejas}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      aquejas: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Se ha colocado Botulinica (Botox)</h5>
                <InputSwitch
                  id="botox"
                  checked={clienteFicha.botox}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      botox: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Se ha colocado otros Fillers</h5>
                <InputSwitch
                  id="fillers"
                  checked={clienteFicha.fillers}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      fillers: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="my-2 row">
              <div className="col-sm">
                <h5>Ultima vez que se coloco Botox o Fillers</h5>
                <Calendar
                  value={clienteFicha.ultimaVezBotoxFillers}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      ultimaVezBotoxFillers: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-sm">
                <h5>Tuvo alguna reaccion</h5>
                <InputSwitch
                  id="reaccionBotoxFillers"
                  checked={clienteFicha.reaccionBotoxFillers}
                  onChange={(e) =>
                    setClienteFicha({
                      ...clienteFicha,
                      reaccionBotoxFillers: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="my-3">
        <button type="submit" className="btn btn-submit btn-primary">
          Crear Cliente
        </button>
      </div>
    </form>
  );
}

export default ClienteCreateForm;
