import {
  addDays,
  addWeeks,
  addMonths,
  subDays,
  subWeeks,
  subMonths,
} from "date-fns";
import format from "date-fns/format";
import startOfDay from "date-fns/startOfDay";
import endOfDay from "date-fns/endOfDay";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";

class DateUtils {
  getTodayInterval = () => {
    const startOfDayUTC = startOfDay(new Date()).getTime();
    const endOfDayUTC = endOfDay(new Date()).getTime();

    return [startOfDayUTC, endOfDayUTC];
  };

  getWeekInterval = () => {
    const startOfWeekUtc = startOfWeek(new Date()).getTime();
    const endOfWeekUtc = endOfWeek(new Date()).getTime();

    return [startOfWeekUtc, endOfWeekUtc];
  };

  getMonthInterval = () => {
    const startOfMonthUtc = startOfMonth(new Date()).getTime();
    const endOfMonthUtc = endOfMonth(new Date()).getTime();

    return [startOfMonthUtc, endOfMonthUtc];
  };

  convertTimestampToLocal = (dateTimestamp, frmat = "MMMM d, y") => {
    return format(new Date(dateTimestamp), frmat);
  };

  addToInterval = (interval, type) => {
    let startDate;
    let endDate;

    switch (type) {
      case "days":
        startDate = addDays(new Date(interval.startDate), 1).getTime();
        endDate = addDays(new Date(interval.endDate), 1).getTime();
        return [startDate, endDate];
      case "weeks":
        startDate = addWeeks(new Date(interval.startDate), 1).getTime();
        endDate = subDays(addWeeks(startDate, 1), 1).getTime();
        return [startDate, endDate];
      case "months":
        startDate = addMonths(new Date(interval.startDate), 1).getTime();
        endDate = subDays(addMonths(startDate, 1), 1).getTime();
        return [startDate, endDate];
      default:
        break;
    }
  };

  subtractFromInterval = (interval, type) => {
    let startDate;
    let endDate;

    switch (type) {
      case "days":
        startDate = subDays(new Date(interval.startDate), 1).getTime();
        endDate = subDays(new Date(interval.endDate), 1).getTime();
        return [startDate, endDate];
      case "weeks":
        startDate = subWeeks(new Date(interval.startDate), 1).getTime();
        endDate = subDays(addWeeks(startDate, 1), 1).getTime();
        return [startDate, endDate];
      case "months":
        startDate = subMonths(new Date(interval.startDate), 1).getTime();
        endDate = subDays(addMonths(startDate, 1), 1).getTime();
        return [startDate, endDate];
      default:
        break;
    }
  };

  getDayFromTimestamp = (timestamp) => {
    return format(startOfDay(new Date(timestamp)), "eeee, MMMM d, y");
  };
}

export default DateUtils;
