import React, { Fragment } from "react";
import CitasCalendar from "../../components/cita/CitasCalendar";

const Cita = () => {
  return (
    <Fragment>
      <h5 className="my-2">
        <i className="fa-regular fa-calendar-check"></i> Citas
      </h5>
      <div className="card all-center my-3 main-card">
        <div className="card-body w-100">
          <CitasCalendar />
        </div>
      </div>
    </Fragment>
  );
};

export default Cita;
