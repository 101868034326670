import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/animations/Loader";
import ClienteDetails from "../../components/cliente/ClienteDetails";
import CitasCRMContext from "../../context/citasCRM/citasCRMContext";

function Cliente() {
  const citasCRMContext = useContext(CitasCRMContext);

  const navigate = useNavigate();
  const cliente_id = window.location.href.split("/")[4];

  useEffect(() => {
    citasCRMContext.getCliente(cliente_id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <button className="btn ps-0" onClick={() => navigate(-1)}>
        <i className="fa-solid fa-chevron-left"></i> Atras
      </button>
      <div className="my-3">
        <i className="fa-solid fa-user"></i>{" "}
        <span className="fs-5">Cliente Detalle</span>
      </div>
      {citasCRMContext.loading ? (
        <Loader />
      ) : (
        <ClienteDetails cliente={citasCRMContext.cliente} />
      )}
    </div>
  );
}

export default Cliente;
