import React, { Fragment } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { differenceInYears } from "date-fns";
import DateUtils from "../../utils/DateUtils";

const ClienteHistoriaClinica = ({ cliente_ficha }) => {
  const dateFormatUpdate = "eeee, MMMM d, y, h:mm aa";
  const dateFormatDOB = "MMMM d, y";
  const dateUtils = new DateUtils();
  return (
    <Fragment>
      <div>
        <span className="fw-light">Ultima actualizacion:</span>{" "}
        {cliente_ficha.fechaEpoch ? (
          <div className="badge rounded-pill text-bg-secondary">
            {dateUtils.convertTimestampToLocal(
              cliente_ficha.fechaEpoch,
              dateFormatUpdate
            )}
          </div>
        ) : (
          <div className="badge rounded-pill text-bg-info">Nunca</div>
        )}
      </div>

      <hr></hr>

      <Accordion className="accordion-custom mt-2">
        <AccordionTab
          header={
            <Fragment>
              <i className="pi pi-calendar"></i>
              <span className="ms-1">Datos Personales</span>
            </Fragment>
          }
        >
          <div>
            <span className="fw-light">Edad:</span>{" "}
            {cliente_ficha.fechaNacimientoEpoch
              ? getAge(cliente_ficha.fechaNacimientoEpoch)
              : ""}
          </div>
          <div>
            <span className="fw-light">Ocupacion:</span>{" "}
            {cliente_ficha.ocupacion}
          </div>
          <div>
            <span className="fw-light">Fecha de nacimiento:</span>{" "}
            {cliente_ficha.fechaNacimientoEpoch
              ? dateUtils.convertTimestampToLocal(
                  cliente_ficha.fechaNacimientoEpoch,
                  dateFormatDOB
                )
              : ""}
          </div>
          <div>
            <span className="fw-light">Genero:</span> {cliente_ficha.genero}
          </div>
          <div>
            <span className="fw-light">Motivo de visita:</span>{" "}
            {cliente_ficha.motivo}
          </div>
        </AccordionTab>
        <AccordionTab
          header={
            <Fragment>
              <i className="fa-solid fa-briefcase-medical"></i>
              <span className="ms-1">Datos Medicos Generales</span>
            </Fragment>
          }
        >
          <div>
            <span className="fw-light">
              Ha tenido o tiene alguna condicion medica:
            </span>{" "}
            {cliente_ficha.condicionMedica ? "Si" : "No"}
          </div>
          <div>
            <span className="fw-light">Detalle de condicion medica:</span>{" "}
            {cliente_ficha.condicionMedicaDetalle}
          </div>
          <div>
            <span className="fw-light">Fuma:</span>{" "}
            {cliente_ficha.fuma ? "Si" : "No"}
          </div>
          <div>
            <span className="fw-light">Toma Alcohol:</span>{" "}
            {cliente_ficha.alcohol ? "Si" : "No"}
          </div>
          <div>
            <span className="fw-light">Hooka:</span>{" "}
            {cliente_ficha.hooka ? "Si" : "No"}
          </div>
          <div>
            <span className="fw-light">Enfermedades (ha tenido o tiene):</span>{" "}
            {cliente_ficha.enfermedades}
          </div>
        </AccordionTab>
        <AccordionTab
          header={
            <Fragment>
              <i className="fa-solid fa-hospital-user"></i>
              <span className="ms-1">Antecedentes Medicos</span>
            </Fragment>
          }
        >
          <div>
            <span className="fw-light">Esta embarazada:</span>{" "}
            {cliente_ficha.embarazada ? "Si" : "No"}
          </div>
          <div>
            <span className="fw-light">Semanas de embarazo:</span>{" "}
            {cliente_ficha.semanasEmbarazo}
          </div>
          <div>
            <span className="fw-light">Fecha de ultimo embarazo:</span>{" "}
            {cliente_ficha.fechaUltimoEmbarazoEpoch
              ? dateUtils.convertTimestampToLocal(
                  cliente_ficha.fechaUltimoEmbarazoEpoch,
                  dateFormatDOB
                )
              : ""}
          </div>
          <div>
            <span className="fw-light">Esta lactando:</span>{" "}
            {cliente_ficha.lactando}
          </div>
          <div>
            <span className="fw-light">Se ha depilado:</span>{" "}
            {cliente_ficha.depilado ? "Si" : "No"}
          </div>
          <div>
            <span className="fw-light">Areas y metodo de depilacion</span>{" "}
            {cliente_ficha.areasMetodoDepilacion}
          </div>
          <div>
            <span className="fw-light">Tratamientos dermatologicos:</span>{" "}
            {cliente_ficha.tratamientoDermatologicos}
          </div>
          <div>
            <span className="fw-light">Medicamentos de consumo actual:</span>{" "}
            {cliente_ficha.medicamentos}
          </div>
          <div>
            <span className="fw-light">Horas de sueño:</span>{" "}
            {cliente_ficha.horasSueno}
          </div>
          <div>
            <span className="fw-light">Vasos de agua que consume al dia:</span>{" "}
            {cliente_ficha.vasosAguaDia}
          </div>
          <div>
            <span className="fw-light">Consume frutas y vegetales:</span>{" "}
            {cliente_ficha.consumeFrutasVegetales ? "Si" : "No"}
          </div>
          <div>
            <span className="fw-light">Tiene periodo menstrual regular:</span>{" "}
            {cliente_ficha.periodoMenstrualRegular ? "Si" : "No"}
          </div>
          <div>
            <span className="fw-light">Fecha de ultimo periodo:</span>{" "}
            {cliente_ficha.fechaUltimoPeriodoEpoch
              ? dateUtils.convertTimestampToLocal(
                  cliente_ficha.fechaUltimoPeriodoEpoch,
                  dateFormatDOB
                )
              : ""}
          </div>
          <div>
            <span className="fw-light">
              Productos faciales cosmeticos de uso diario:
            </span>{" "}
            {cliente_ficha.productosFacialesCosmeticos}
          </div>
          <div>
            <span className="fw-light">
              Tratamientos faciales que realiza en casa:
            </span>{" "}
            {cliente_ficha.tratamientosFacialesCasa}
          </div>
          <div>
            <span className="fw-light">
              Herpes Simplex (tiene o ha tenido):
            </span>{" "}
            {cliente_ficha.herpesSimplex ? "Si" : "No"}
          </div>
          <div>
            <span className="fw-light">Usa lentes de contacto:</span>{" "}
            {cliente_ficha.lentesContacto ? "Si" : "No"}
          </div>
          <div>
            <span className="fw-light">Ha sufrido de acné:</span>{" "}
            {cliente_ficha.acne ? "Si" : "No"}
          </div>
          <div>
            <span className="fw-light">Tiene problemas de cicatrizacion:</span>{" "}
            {cliente_ficha.problemasCicatrizacion ? "Si" : "No"}
          </div>
          <div>
            <span className="fw-light">Aquejas:</span> {cliente_ficha.aquejas}
          </div>
          <div>
            <span className="fw-light">Se ha colocado Botulinica(Botox):</span>{" "}
            {cliente_ficha.botox ? "Si" : "No"}
          </div>
          <div>
            <span className="fw-light">Se ha colocado otros Fillers:</span>{" "}
            {cliente_ficha.fillers ? "Si" : "No"}
          </div>
          <div>
            <span className="fw-light">
              Ultima vez que se coloco Botox o Fillers:
            </span>{" "}
            {cliente_ficha.ultimaVezBotoxFillers}
          </div>
          <div>
            <span className="fw-light">Tuvo alguna reaccion:</span>{" "}
            {cliente_ficha.reaccionBotoxFillers ? "Si" : "No"}
          </div>
        </AccordionTab>
        <AccordionTab
          header={
            <Fragment>
              <i className="fa-solid fa-user-doctor"></i>
              <span className="ms-1">Espacio de uso del Profesional</span>
            </Fragment>
          }
        >
          <div>
            <span className="fw-light">Analisis realizado:</span>{" "}
            {cliente_ficha.analisisRealizado}
          </div>
          <div>
            <span className="fw-light">Apariencia:</span>{" "}
            {cliente_ficha.apariencia}
          </div>
          <div>
            <span className="fw-light">Textura:</span> {cliente_ficha.textura}
          </div>
          <div>
            <span className="fw-light">Sensibilidad:</span>{" "}
            {cliente_ficha.sensibilidad}
          </div>
          <div>
            <span className="fw-light">Atopica:</span> {cliente_ficha.atopica}
          </div>
          <div>
            <span className="fw-light">Pigmentaciones:</span>{" "}
            {cliente_ficha.pigmentaciones}
          </div>
          <div>
            <span className="fw-light">Fototipo:</span> {cliente_ficha.fototipo}
          </div>
          <div>
            <span className="fw-light">Biotipo:</span> {cliente_ficha.biotipo}
          </div>
          <div>
            <span className="fw-light">Tratamiento realizado:</span>{" "}
            {cliente_ficha.tratamientoRealizado}
          </div>
          <div>
            <span className="fw-light">Linea utilizada:</span>{" "}
            {cliente_ficha.lineaUtilizada}
          </div>
          <div>
            <span className="fw-light">Productos sugeridos:</span>{" "}
            {cliente_ficha.productosSugeridos}
          </div>
          <div>
            <span className="fw-light">
              Sugerencias para la proxima visita:
            </span>{" "}
            {cliente_ficha.sugerenciasProximaVisita}
          </div>
        </AccordionTab>
      </Accordion>
    </Fragment>
  );
};

function getAge(dob) {
  const date = new Date(dob);
  const age = differenceInYears(new Date(), date);
  return age;
}

export default ClienteHistoriaClinica;
