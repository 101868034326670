import React from "react";
import { Outlet } from "react-router-dom";

function SettingsContent() {
  return (
    <div className="card my-2 w-100">
      <div className="card-body scroll">
        <Outlet />
      </div>
    </div>
  );
}

export default SettingsContent;
