import React, { useContext } from "react";
import { Toast } from "primereact/toast";
import CitasCRMContext from "../../context/citasCRM/citasCRMContext";

const Alert = () => {
  const citasCRMContext = useContext(CitasCRMContext);

  return <Toast ref={citasCRMContext.toast} />;
};

export default Alert;
