import axios from "axios";

class ServicioService {
  async listServicios(negocio_id, categoria, nombre) {
    try {
      let params = new URLSearchParams();
      if (categoria) params.append("categoria", categoria);
      if (nombre) params.append("nombre", nombre);
      const res = await axios.get(
        "/data/servicios.json",
        (params = { params })
      );
      return res.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async getServicioById(id) {
    try {
      const res = await axios.get("/data/servicios.json");
      const servicio = res.data.filter(
        (servicio) => servicio.servicio_id === id
      )[0];
      return servicio;
    } catch (error) {
      console.log(error);
      return {};
    }
  }
}

export default ServicioService;
