import axios from "axios";

class CitaService {
  async listCita(negocio_id, startDate, endDate, phone) {
    try {
      let params = new URLSearchParams();
      if (startDate) params.append("fecha_epoch_start", startDate);

      if (endDate) params.append("fecha_epoch_end", endDate);

      if (phone) params.append("cliente_telefono", phone);

      const res = await axios.get("/data/citas.json", (params = { params }));
      return res.data;
    } catch (error) {
      console.log(error);
      return [];
    }
  }

  async getCita(negocio_id, citaId) {
    try {
      const res = await axios.get("/data/citas.json");
      const cita = res.data.filter((cita) => cita.citaId === citaId)[0];
      return cita;
    } catch (error) {
      console.log(error);
      return {};
    }
  }
}

export default CitaService;
