import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { Chip } from "primereact/chip";

function PlantillaCreateForm() {
  const [plantillaNombre, setPlantillaNombre] = useState();
  const [plantillaTipo, setPlantillaTipo] = useState();
  const [plantillaIsDefault, setPlantillaIsDefault] = useState(false);
  const [mensaje, setMensaje] = useState();

  const tiposDePlantilla = [
    { label: "Antes de Servicio", value: "antesDeServicio" },
    { label: "Despues de Servicio", value: "despuesDeServicio" },
  ];

  const appendToMensaje = (dynamicContent) => {
    setMensaje((mensaje || "") + dynamicContent);
  };

  const onSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="my-2 row">
        <div className="col-sm">
          <h5>Plantilla Nombre</h5>
          <InputText
            id="plantilla-nombre"
            value={plantillaNombre}
            onChange={(e) => {
              setPlantillaNombre(e.target.value);
            }}
          />
        </div>
        <div className="col-sm">
          <h5>Plantilla por defecto</h5>
          <InputSwitch
            checked={plantillaIsDefault}
            onChange={(e) => setPlantillaIsDefault(e.target.value)}
          />
        </div>
      </div>
      <div className="my-2">
        <h5>Plantilla Tipo</h5>
        <Dropdown
          value={plantillaTipo}
          options={tiposDePlantilla}
          onChange={(e) => setPlantillaTipo(e.target.value)}
          placeholder="Selecciona un tipo de Plantilla"
        />
      </div>
      <h4 className="my-2">Contenido de la Plantilla</h4>
      <div className="my-2 row">
        <div className="col-sm">
          <h5>Mensaje</h5>
          <InputTextarea
            value={mensaje}
            onChange={(e) => setMensaje(e.target.value)}
            rows={5}
            cols={30}
            autoResize
          />
        </div>
        <div className="col-sm">
          <h5>Campos Dinamicos</h5>
          <div className="container">
            <Chip
              label="ClienteNombre"
              className="m-1 btn"
              onClick={() => appendToMensaje("{{cliente_nombres}}")}
            />
            <Chip
              label="ClienteApellido"
              className="m-1 btn"
              onClick={() => appendToMensaje("{{cliente_apellidos}}")}
            />
            <Chip
              label="FechaCita"
              className="m-1 btn"
              onClick={() => appendToMensaje("{{cita_fecha}}")}
            />
            <Chip
              label="NegocioNombre"
              className="m-1 btn"
              onClick={() => appendToMensaje("{{negocio_nombre}}")}
            />
            <Chip
              label="NegocioContactos"
              className="m-1 btn"
              onClick={() => appendToMensaje("{{negocio_contactos}}")}
            />
            <Chip
              label="NegocioDireccion"
              className="m-1 btn"
              onClick={() => appendToMensaje("{{negocio_direccion}}")}
            />
          </div>
        </div>
      </div>
      <div className="my-2">
        <button type="submit" className="btn btn-submit btn-primary">
          Crear Plantilla
        </button>
      </div>
    </form>
  );
}

export default PlantillaCreateForm;
