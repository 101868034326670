import React from "react";
import { NavLink } from "react-router-dom";

function SettingsNavigation() {
  return (
    <ul className="nav flex-column m-2">
      <li className="nav-item my-2">
        <NavLink to="perfil">
          <i className="fa-solid fa-address-card"></i> Perfil
        </NavLink>
      </li>
      <li className="nav-item my-2">
        <NavLink to="negocio">
          <i className="fa-solid fa-briefcase"></i> Negocio
        </NavLink>
      </li>
      <li className="nav-item my-2">
        <NavLink to="servicios">
          <i className="fa-solid fa-hand-holding-heart"></i> Servicios
        </NavLink>
      </li>
      <li className="nav-item my-2">
        <NavLink to="mensajeria">
          <i className="fa-regular fa-comment-dots"></i> Mensajeria
        </NavLink>
      </li>
      <li className="nav-item my-2">
        <NavLink to="usuarios">
          <i className="fa-solid fa-users"></i> Usuarios
        </NavLink>
      </li>
    </ul>
  );
}

export default SettingsNavigation;
