import React, { Fragment } from "react";

function ServicioView() {
  return (
    <Fragment>
      <h5>Servicio Route here!</h5>
    </Fragment>
  );
}

export default ServicioView;
