import React from "react";
import SettingsContent from "../../components/settings/SettingsContent";
import SettingsNavigation from "../../components/settings/SettingsNavigation";

function Settings() {
  return (
    <div>
      <h5 className="my-2">
        <i className="fa-solid fa-gear"></i> Configuracion
      </h5>
      <div className="d-sm-flex">
        <div>
          <SettingsNavigation />
        </div>
        <div className="flex-grow-1">
          <SettingsContent />
        </div>
      </div>
    </div>
  );
}

export default Settings;
