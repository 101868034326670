import React from "react";
import { Avatar } from "primereact/avatar";
import { NavLink } from "react-router-dom";

const ClienteItem = ({
  cliente: { cliente_id, cliente_nombres, cliente_apellidos, cliente_telefono },
}) => {
  const getRandomColor = () =>
    "#" + Math.floor(Math.random() * 16777215).toString(16);

  return (
    <div className="card m-2 w-100">
      <div className="card-body text-center">
        <Avatar
          icon="pi pi-user"
          className="mr-2"
          size="xlarge"
          shape="circle"
          style={{
            backgroundColor: getRandomColor(),
            color: "#fff",
          }}
        />
        <h5 className="my-2">
          {cliente_nombres} {cliente_apellidos}
        </h5>
        <div className="my-2">
          <i className="pi pi-whatsapp" style={{ color: "#07a384" }} />{" "}
          {`${cliente_telefono.slice(2, 5)}-${cliente_telefono.slice(
            5,
            8
          )}-${cliente_telefono.slice(8)}`}
        </div>

        <NavLink
          to={`/cliente/${cliente_id}`}
          className="btn btn-light btn-sm my-2"
        >
          Info
        </NavLink>
      </div>
    </div>
  );
};

export default ClienteItem;
