import React, { Fragment, useState } from "react";
import { InputText } from "primereact/inputtext";

function PerfilView() {
  const [user, setUser] = useState({});
  return (
    <Fragment>
      <div className="my-2">
        <h5>
          <i className="fa-solid fa-address-card"></i> Perfil
        </h5>
      </div>
      <div className="my-2">
        <h4>Datos de usuario</h4>
        <h5>Username</h5>
        <InputText value={user.user_name} disabled />
      </div>
    </Fragment>
  );
}

export default PerfilView;
