import React, { useReducer, useRef } from "react";
import CitaService from "../../services/CitaService";
import ClienteService from "../../services/ClienteService";
import CitasCRMContext from "./citasCRMContext";
import CitasCRMReducer from "./citasCRMReducer";
import {
  LIST_CLIENTES,
  GET_CLIENTE,
  LIST_CITAS,
  SET_CITAS_GROUPED_BY_DAY,
  SET_CITAS_DATE,
  SET_CITAS_DATE_STR,
  SET_INTERVAL_TYPE,
  GET_CITA,
  SET_LOADING,
  LIST_SERVICIOS,
  GET_SERVICIO,
} from "../types";
import DateUtils from "../../utils/DateUtils";
import ServicioService from "../../services/ServicioService";

const citaService = new CitaService();
const clienteService = new ClienteService();
const servicioService = new ServicioService();
const dateUtils = new DateUtils();

const CitasCRMState = (props) => {
  const initialState = {
    negocio_id: "",
    citas: [],
    citasGroupedByDay: {},
    citasDate: {
      startDate: null,
      endDate: null,
    },
    citasDateStr: "",
    intervalType: "DAY",
    cita: {},
    clientes: [],
    cliente: {},
    servicios: [],
    servicio: {},
    loading: false,
    toast: useRef(null),
  };

  const [state, dispatch] = useReducer(CitasCRMReducer, initialState);

  // *********************** //
  // ** CITAS ** //

  // Set CitasDate
  const setCitasDate = ({ startDate, endDate }) => {
    dispatch({
      type: SET_CITAS_DATE,
      payload: { startDate, endDate },
    });
  };

  // Set citasDateStr
  const setCitasDateStr = (interval) => {
    dispatch({
      type: SET_CITAS_DATE_STR,
      payload: interval,
    });
  };

  // Set Interval Type
  const setIntevalType = (intervalType) => {
    dispatch({
      type: SET_INTERVAL_TYPE,
      payload: intervalType,
    });
  };

  // Side Effects
  const daySetDateAndDateStr = (startDate, endDate) => {
    let citasDateStr = dateUtils.convertTimestampToLocal(startDate);
    setCitasDate({ startDate, endDate });
    setCitasDateStr(citasDateStr);
  };
  const weekSetDateAndDateStr = (startDate, endDate) => {
    const citasWeekStart = dateUtils.convertTimestampToLocal(startDate);
    const cistasWeekEnd = dateUtils.convertTimestampToLocal(endDate);
    let citasDateStr = `${citasWeekStart} - ${cistasWeekEnd}`;
    setCitasDate({ startDate, endDate });
    setCitasDateStr(citasDateStr);
  };
  const monthSetDateAndDateStr = (startDate, endDate) => {
    const citasMonthStart = dateUtils.convertTimestampToLocal(startDate);
    const citasMonthEnd = dateUtils.convertTimestampToLocal(endDate);
    let citasDateStr = `${citasMonthStart} - ${citasMonthEnd}`;
    setCitasDate({ startDate, endDate });
    setCitasDateStr(citasDateStr);
  };

  // Add to date
  const addToDate = async () => {
    let startDate;
    let endDate;
    switch (state.intervalType) {
      case "DAY":
        [startDate, endDate] = dateUtils.addToInterval(state.citasDate, "days");
        daySetDateAndDateStr(startDate, endDate);
        await listCitas(startDate, endDate);
        return;

      case "WEEK":
        [startDate, endDate] = dateUtils.addToInterval(
          state.citasDate,
          "weeks"
        );
        weekSetDateAndDateStr(startDate, endDate);
        await listCitas(startDate, endDate);
        return;

      case "MONTH":
        [startDate, endDate] = dateUtils.addToInterval(
          state.citasDate,
          "months"
        );
        monthSetDateAndDateStr(startDate, endDate);
        await listCitas(startDate, endDate);
        return;

      default:
        return;
    }
  };

  // Substract from date
  const subtractFromDate = async () => {
    let startDate;
    let endDate;
    switch (state.intervalType) {
      case "DAY":
        [startDate, endDate] = dateUtils.subtractFromInterval(
          state.citasDate,
          "days"
        );
        daySetDateAndDateStr(startDate, endDate);
        await listCitas(startDate, endDate);
        return;

      case "WEEK":
        [startDate, endDate] = dateUtils.subtractFromInterval(
          state.citasDate,
          "weeks"
        );
        weekSetDateAndDateStr(startDate, endDate);
        await listCitas(startDate, endDate);
        return;

      case "MONTH":
        [startDate, endDate] = dateUtils.subtractFromInterval(
          state.citasDate,
          "months"
        );
        monthSetDateAndDateStr(startDate, endDate);
        await listCitas(startDate, endDate);
        return;

      default:
        break;
    }
  };

  // List Citas by Interval Helper
  const listCitasByInterval = async (interval) => {
    let startDate;
    let endDate;
    switch (interval) {
      case "TODAY":
        [startDate, endDate] = dateUtils.getTodayInterval();
        daySetDateAndDateStr(startDate, endDate);
        setIntevalType("DAY");
        await listCitas(startDate, endDate);
        return;

      case "WEEK":
        [startDate, endDate] = dateUtils.getWeekInterval();
        weekSetDateAndDateStr(startDate, endDate);
        setIntevalType("WEEK");
        await listCitas(startDate, endDate);
        return;

      case "MONTH":
        [startDate, endDate] = dateUtils.getMonthInterval();
        monthSetDateAndDateStr(startDate, endDate);
        setIntevalType("MONTH");
        await listCitas(startDate, endDate);
        return;

      default:
        return;
    }
  };

  // List Citas
  const groupCitasByDay = (citasList) => {
    const groups = {};
    citasList.forEach((cita) => {
      const day = dateUtils.getDayFromTimestamp(cita.fecha_epoch);
      if (!groups[day]) {
        groups[day] = [];
      }
      groups[day].push(cita);
    });
    return groups;
  };

  const setCitasGroupedByDay = (citasList) => {
    dispatch({
      type: SET_CITAS_GROUPED_BY_DAY,
      payload: groupCitasByDay(citasList),
    });
  };

  const listCitas = async (startDate, endDate, phone) => {
    setLoading();
    let data = await citaService.listCita(
      state.negocio_id,
      startDate,
      endDate,
      phone
    );
    dispatch({
      type: LIST_CITAS,
      payload: data,
    });
    setCitasGroupedByDay(data);
  };

  // Get Cita
  const getCita = async (cita_id) => {
    setLoading();
    let data = await citaService.getCita(state.negocio_id, cita_id);
    dispatch({
      type: GET_CITA,
      payload: data,
    });
  };

  // *********************** //
  // ** CLIENTES ** //

  // List Clientes
  const listClientes = async (phone, name) => {
    setLoading();
    let data = await clienteService.listClientes(state.negocio_id, phone, name);
    dispatch({
      type: LIST_CLIENTES,
      payload: data,
    });
  };

  // Get Cliente
  const getCliente = async (cliente_id) => {
    setLoading();
    let data = await clienteService.getClienteById(cliente_id);
    dispatch({
      type: GET_CLIENTE,
      payload: data,
    });
  };

  // *********************** //
  // ** SERVICIOS ** //

  // List Servicios
  const listServicios = async (categoria, nombre) => {
    setLoading();
    let data = await servicioService.listServicios(
      state.negocio_id,
      categoria,
      nombre
    );
    dispatch({
      type: LIST_SERVICIOS,
      payload: data,
    });
  };

  // Get Servicio
  const getServicio = async (servicio_id) => {
    setLoading();
    let data = await servicioService.getServicioById(servicio_id);
    dispatch({
      type: GET_SERVICIO,
      payload: data,
    });
  };

  // *********************** //
  // ** UTILS ** //

  // Set Alert
  const setAlert = ({ severity, summary, detail, life }) => {
    state.toast.current.show({ severity, summary, detail, life });
  };

  // Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <CitasCRMContext.Provider
      value={{
        citas: state.citas,
        citasGroupedByDay: state.citasGroupedByDay,
        citasDate: state.citasDate,
        citasDateStr: state.citasDateStr,
        intervalType: state.intervalType,
        cita: state.cita,
        clientes: state.clientes,
        cliente: state.cliente,
        servicios: state.servicios,
        servicio: state.servicio,
        loading: state.loading,
        toast: state.toast,
        listCitasByInterval,
        listCitas,
        getCita,
        listClientes,
        getCliente,
        listServicios,
        getServicio,
        addToDate,
        subtractFromDate,
        setAlert,
      }}
    >
      {props.children}
    </CitasCRMContext.Provider>
  );
};

export default CitasCRMState;
