import React, { Fragment } from "react";

function MensajeriaView() {
  return (
    <Fragment>
      <h5>Mensajeria Route here!</h5>
    </Fragment>
  );
}

export default MensajeriaView;
